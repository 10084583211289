import { useEventListener } from "@castia/sdk";
import environment from "../../../../utils/environment";
import { useCallback } from "react";
import { RetryingFetchData, useRetryingFetch } from "../../../useRetryingFetch";
import { Font } from "../../../../model/Font";
import { LocalEvents } from "../../../../events/LocalEvents";

export function useFetchAvailableFonts(): RetryingFetchData<Font[]> {
    const fetch = useRetryingFetch<Font[]>(
        `${environment.apiBaseUrl}/api/v1/media/fonts/available`,
    );

    const eventCallback = useCallback((): void => {
        fetch.refreshData();
    }, []);

    useEventListener(LocalEvents.CONTENT_UPDATE, eventCallback);

    return fetch;
}
