import React, { ReactElement, useRef } from "react";
import { EventContext } from "@castia/sdk";
import { EventEmitter } from "events";

/**
 * Provider for the EventEmitter context.
 * @param props
 * @constructor
 */
export function EventContextProvider(
    props: React.PropsWithChildren<unknown>
): ReactElement {
    const eventEmitter = useRef<EventEmitter>();

    if (!eventEmitter.current) {
        eventEmitter.current = new EventEmitter();
    }

    return (
        <EventContext.Provider value={eventEmitter.current}>
            {props.children}
        </EventContext.Provider>
    );
}
