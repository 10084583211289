import useFetchPersist, {PersistData} from "../../useFetchPersist";
import environment from "../../../utils/environment";

/**
 * Update token request hook.
 */
function useUpdateToken(): PersistData {
    return useFetchPersist(`${environment.apiBaseUrl}/api/v1/devices/token`, "POST", false);
}

export default useUpdateToken;
