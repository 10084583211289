import { useEffect } from "react";
import { useCreateDeviceScreenshot } from "./api/device/useCreateDeviceScreenshot";
import useAuthentication from "./useAuthentication";
import html2canvas from "html2canvas";
import { useTakeDaemonScreenshot } from "./daemon-integration/useTakeDaemonScreenshot";
import { useAppContext } from "../core/context/useAppContext";
import { AppVariant } from "../core/context/AppContext";

// Set interval time to a non-round number to increase the odds of catching different scenes in the screenshots.
const SCREENSHOT_INTERVAL = 583500;

/**
 * Capture a screenshot of all video elements currently in the DOM. This is necessary because videos will not
 * show up on screenshots created with html-to-image or similar tools. This function will set the screenshot as a
 * background image on the video element, thus allowing it to be captured.
 */
function captureVideos() {
    // Create an in-memory canvas element to use as a context for drawing a screenshot of the videos
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
        if (!video.src && video.children.length === 0) {
            return;
        }

        try {
            const w = video.videoWidth;
            const h = video.videoHeight;
            canvas.width = w;
            canvas.height = h;
            ctx.fillRect(0, 0, w, h);
            ctx.drawImage(video, 0, 0, w, h);
            video.style.backgroundImage = `url(${canvas.toDataURL()})`; // here is the magic
            video.style.backgroundSize = "contain";
            video.style.backgroundRepeat = "no-repeat";
            video.style.backgroundPosition = "center";
            ctx.clearRect(0, 0, w, h); // clean the canvas
        } catch (e) {
            return;
        }
    });
}

export function useTakeScreenshots(): () => void {
    const { getDeviceId } = useAuthentication();
    const { appVariant } = useAppContext();
    const { sendRequest } = useCreateDeviceScreenshot(getDeviceId());
    const takeDaemonScreenshot = useTakeDaemonScreenshot();

    async function takeScreenshot() {
        if (appVariant === AppVariant.ELECTRON && window.Castia.screenshot) {
            await takeNativeScreenshot();
        } else if (appVariant === AppVariant.DAEMON) {
            const blob = await takeDaemonScreenshot();
            blob && await postScreenshot(blob);
        } else {
            takeInBrowserScreenshot();
        }
    }

    async function takeNativeScreenshot() {
        const image = await window.Castia.screenshot();
        const blob = new Blob([image]);
        await postScreenshot(blob);
    }

    function takeInBrowserScreenshot() {
        captureVideos();

        html2canvas(document.body, {
            allowTaint: true,
            useCORS: true,
            imageTimeout: 30000,
            scrollX: 0,
            scrollY: 0,
            removeContainer: true,
        }).then((canvas) => {
            canvas.toBlob(async (blob) => {
                await postScreenshot(blob);
            });
        });
    }

    async function postScreenshot(blob: Blob) {
        const formData = new FormData();
        formData.append("file", blob, "screenshot.png");
        await sendRequest(formData);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            takeScreenshot();
        }, SCREENSHOT_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return takeScreenshot;
}
