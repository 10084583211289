import { PublicTenantProfile } from "model/PublicTenantProfile";
import {FetchData, useFetch} from "@castia/sdk";
import environment from "../../../utils/environment";

export function usePublicTenantProfile(): FetchData<PublicTenantProfile> {
    return useFetch<PublicTenantProfile>(
        `${environment.apiBaseUrl}/api/v1/context`,
        null,
        {
            skipAuth: true,
        }
    );
}
