import {useContext} from "react";
import AuthContext from "../services/auth/AuthContext";
import {Auth} from "../services/auth/AuthContextProvider";

/**
 * Get access to the authentication context.
 */
function useAuthentication(): Auth {
    return useContext(AuthContext);
}

export default useAuthentication;
