import React, {ReactElement} from "react";
import { Font } from "../model/Font";
import { useFetchAvailableFonts } from "../hooks/api/media/font/useFetchAvailableFonts";

export function FontFace(): ReactElement {
    const { response } = useFetchAvailableFonts();

    if (!response) {
        return <></>;
    }

    function generateHtml() {
        return response
            .map((font: Font) => {
                return `
            @font-face {
                font-family: "${font.fontFamily}";
                src: url(${font.location});
            }
            `;
            })
            .join("\n");
    }

    return <style dangerouslySetInnerHTML={{ __html: generateHtml() }}></style>;
}
