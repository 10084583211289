/* eslint-disable @typescript-eslint/no-explicit-any */
import {default as React, ReactElement, useEffect, useRef, useState} from "react";
import { io, Socket } from 'socket.io-client';
import environment from "../../utils/environment";
import styles from "./RegistrationPage.scss";
import image from "../../static/unsplash-landscape.jpg";
import RegistrationCode from "./RegistrationCode/RegistrationCode";
import LoaderCheckMark from "../../components/LoaderCheckmark/LoaderCheckMark";
import {sleep} from "../../utils/sleep";
import useCreateDevice from "../../hooks/api/device/useCreateDevice";
import {REFRESH_TOKEN_KEY, TOKEN_KEY} from "../../services/auth/AuthContextProvider";
import logo from "../../static/white-castia-logo.png";
import {useTenantProfileContext} from "../../tenant-profile/TenantProfileContext";
import { Navigate } from "react-router-dom";

/**
 * Generate a random string to use for registration.
 * @param length
 * @param chars
 */
function randomString(length: number, chars: string): string {
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

const LOOK_AT_THE_LOADER = 2000;

/**
 * Page which is displayed if the device is not registered yet.
 * @constructor
 */
function RegistrationPage(): ReactElement {
    const context = useTenantProfileContext();
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [registrationCode, setRegistrationCode] = useState("");
    const {sendRequest} = useCreateDevice();
    const socket = useRef<Socket>();

    useEffect((): void => {
        setRegistrationCode(randomString(8, '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'));
    }, []);

    useEffect((): () => void => {
        if (!registrationCode) {
            return;
        }
        socket.current = io(
            environment.webSocketUrl + '/registration',
            {
                query: {registrationCode: registrationCode},
                withCredentials: true,
            }
        );
        socket.current.on('connect', (): void => {
            setConnected(true);
        });
        socket.current.on('message', (data: any): void => {
            const message = JSON.parse(data);
            setLoading(true);
            sendRequest({
                registrationCode: registrationCode,
                confirmationCode: message.payload.confirmationCode
            }).then(createDeviceCallback);
        });
        socket.current.on('disconnect', (): void => {
            setConnected(false);
        });

        return (): void => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, [registrationCode]);

    async function createDeviceCallback(result: any): Promise<void> {
        if (result.token && result.refreshToken) {
            localStorage.setItem(TOKEN_KEY, result.token);
            localStorage.setItem(REFRESH_TOKEN_KEY, result.refreshToken);
            await sleep(LOOK_AT_THE_LOADER);
            setLoadingComplete(true);
            await sleep(LOOK_AT_THE_LOADER);
            setRegistrationComplete(true);
        } else {
            setLoading(false);
        }
    }

    if (registrationComplete) {
        return <Navigate to="/"/>;
    }

    return <>
        <div className={styles.blurryBackground} style={{backgroundImage: `url(${image})`}}/>
        <div className={styles.registrationPage}>
            <div className={styles.registrationBlock}>
                <img className={styles.logo} src={context.theme.logo || logo} alt="Logo" />
                <div className={styles.explanation}>Enter the code to register the device:</div>
                {loading ? <LoaderCheckMark complete={loadingComplete}/> : <RegistrationCode code={registrationCode}/>}
                {connected ? <div>CONNECTED!</div> : <div>NOT CONNECTED :(</div>}
            </div>
        </div>
    </>;
}

export default RegistrationPage;
