import React, { ReactElement } from "react";
import useFetchChannel from "../../../hooks/api/channel/useFetchChannel";
import Player from "../Player/Player";
import { NoContent } from "../components/NoContent";
import { ResponseErrorRetryHandler } from "../../../components/ResponseErrorRetryHandler/ResponseErrorRetryHandler";

interface PlayerContainerProps {
    channelId: string;
}

/**
 * Container component around the player which fetches the channel to show.
 * @param props
 * @constructor
 */
function PlayerContainer(props: PlayerContainerProps): ReactElement {
    const { response, isLoading, error, countdown } = useFetchChannel(
        props.channelId,
    );

    return (
        <ResponseErrorRetryHandler
            error={error}
            isLoading={isLoading}
            countdown={countdown}
        >
            {response && response.channelScenes.length > 0 ? (
                <Player
                    scenes={response.channelScenes.sort((cs1, cs2) =>
                        cs1.position > cs2.position ? 1 : -1,
                    )}
                    transitionType={response.transitionType}
                />
            ) : (
                <NoContent />
            )}
        </ResponseErrorRetryHandler>
    );
}

export default PlayerContainer;
