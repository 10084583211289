import React from "react";

// eslint-disable-next-line no-shadow
export enum AppVariant {
    WEB,
    ELECTRON,
    DAEMON,
}

export interface AppContextType {
    appVariant: AppVariant;
}

export const AppContext = React.createContext<AppContextType>(null);
