import Plugin from "model/Plugin";
import environment from "../../../utils/environment";
import {FetchData, useEventListener, useFetch} from "@castia/sdk";
import {useCallback} from "react";

/**
 * Fetch the list of enabled plugins
 */
function useFetchEnabledTemplatePlugins(): FetchData<Plugin[]> {
    const fetchData = useFetch<Plugin[]>(`${environment.apiBaseUrl}/api/v1/plugins/installed`);

    const eventCallback = useCallback(() => {
        fetchData.refreshData();
    }, []);

    useEventListener("plugins:reload", eventCallback);

    return fetchData;
}

export default useFetchEnabledTemplatePlugins;
