/* eslint @typescript-eslint/no-explicit-any: 0 */
import useAuthentication from "hooks/useAuthentication";
import authHeaders from "utils/authHeaders";
import parseJsonOrText from "utils/parseJsonOrText";
import * as Sentry from "@sentry/react";

export interface StatelessPersistData {
    sendRequest: (payload?: any, urlValues?: UrlValue[]) => Promise<any | void>;
}

interface UrlValue {
    key: string;
    value: string;
}

function replaceValues(url: string, urlValues: UrlValue[]): string {
    let resultUrl = url;
    urlValues.forEach((urlValue): void => {
        resultUrl = resultUrl.replace(`$${urlValue.key}`, urlValue.value);
    });
    return resultUrl;
}

/**
 * Gives a function to call the API to persist data. Does not put anything in state. This is useful for requests that
 * should happen in the background and have not influence over the UI.
 *
 * The url may contain placeholders for values which are not known at the time of calling the hook. These values must
 * be prefixed with a '$' sign. To set the values, pass them in as the second argument of the sendRequest operation
 * (without prefixing them with $ in that list).
 * @param url
 * @param method
 * @param authenticated If set to false, the jwt header will not be included.
 */
export function useStatelessFetchPersist(
    url: string,
    method: string,
    authenticated = true,
): StatelessPersistData {
    const auth = useAuthentication();

    async function callAPI(
        payload?: any,
        urlValues?: UrlValue[],
    ): Promise<any | void> {
        let request: RequestInit;
        if (authenticated) {
            const jwt = await auth.getToken();
            request = {
                method: method,
                headers: authHeaders(jwt),
            };
        } else {
            request = {
                method: method,
                headers: { "Content-Type": "application/json" },
            };
        }

        if (payload) {
            request.body = JSON.stringify(payload);
        }

        let callUrl = url;
        if (urlValues) {
            callUrl = replaceValues(callUrl, urlValues);
        }

        try {
            const apiResponse = await fetch(callUrl, request);
            if (!apiResponse.ok) {
                const newError = {
                    statusCode: apiResponse.status,
                    message: apiResponse.statusText,
                    timestamp: new Date().getTime(),
                };
                Sentry.captureException(newError);
                return;
            }
            const text = await apiResponse.text();
            // Set the response value to OK if the response is empty to ensure it always has a value when the request
            // was successful.
            return parseJsonOrText(text) || "OK";
        } catch (apiError) {
            Sentry.captureException(apiError);
        }
    }

    return { sendRequest: callAPI };
}
