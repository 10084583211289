import React, { ReactElement, useCallback, useEffect, useState } from "react";
import styles from "./NetworkStatus.scss";
import { FaNetworkWired } from "react-icons/fa";

export function NetworkStatus(): ReactElement {
    const [disconnected, setDisconnected] = useState<boolean>(
        !window.navigator.onLine,
    );
    const handleNetworkStatus = useCallback((event) => {
        setDisconnected(event.type === "offline");
    }, []);

    useEffect(() => {
        window.addEventListener("offline", handleNetworkStatus);
        window.addEventListener("online", handleNetworkStatus);

        return () => {
            window.removeEventListener("offline", handleNetworkStatus);
            window.removeEventListener("online", handleNetworkStatus);
        };
    }, []);

    if (disconnected) {
        return (
            <div className={styles.connectionError}>
                <FaNetworkWired />
            </div>
        );
    }

    return <></>;
}
