import { takeScreenshot } from "../../daemon-integration/takeScreenshot";

export function useTakeDaemonScreenshot() {
    return async (): Promise<Blob> => {
        const resp = await takeScreenshot();
        return resp
            ? fetch("data:image/png;base64," + resp.screenshots[0]).then(
                  (response) => response.blob(),
              )
            : null;
    };
}
