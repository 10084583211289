import React, { useContext } from "react";

interface TenantProfileContextModel {
    auth: {
        realm: string;
        clientName: string;
        url: string;
    };
    theme: {
        logo?: string;
    };
}

export const TenantProfileContext =
    React.createContext<TenantProfileContextModel>(null);

export function useTenantProfileContext(): TenantProfileContextModel {
    return useContext(TenantProfileContext);
}
