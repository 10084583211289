import React, {memo, ReactElement} from "react";
import {Spinner} from "react-bootstrap";
import styles from "./Loader.scss";

/**
 * Bootstrap based loader.
 * @constructor
 */
const Loader: React.FunctionComponent = (): ReactElement => {
    return <Spinner className={styles.loader} animation="border" />;
};

export default memo(Loader);
