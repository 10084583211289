import React, {ReactElement} from "react";
import styles from "./NoContent.scss";
import image from "../../../static/unsplash-landscape.jpg";
import logo from "../../../static/white-castia-logo.png";
import {useTenantProfileContext} from "../../../tenant-profile/TenantProfileContext";

export function NoContent(): ReactElement {
    const context = useTenantProfileContext();

    return <>
        <div className={styles.blurryBackground} style={{backgroundImage: `url(${image})`}}/>
        <div className={styles.content}>
            <img src={context.theme.logo || logo} alt="Logo" />
        </div>
    </>;
}
