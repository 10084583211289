import useFetchPersist, {PersistData} from "../../useFetchPersist";
import environment from "../../../utils/environment";

/**
 * Hook to register a new device.
 */
function useCreateDevice(): PersistData {
    return useFetchPersist(
        `${environment.apiBaseUrl}/api/v1/devices`,
        "POST",
        false
    );
}

export default useCreateDevice;
