import { useEffect } from "react";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import {
    setHours,
    setMinutes,
    setSeconds,
    addDays,
    addSeconds,
} from "date-fns";

const reloadHour = 4;
const reloadVarianceSeconds = 3600;

export function useReloadPageInterval(): void {
    useEffect(() => {
        // Get the current date and time
        const now = new Date();

        // Set the hour, minute, and second of the date to the reloadHour. And add 1 day to always reload it tomorrow.
        let reloadDateTime = addDays(
            setHours(setMinutes(setSeconds(now, 0), 0), reloadHour),
            1,
        );

        // Add a random number of seconds between -3600 and 3600 to the time
        const variance =
            Math.floor(Math.random() * (reloadVarianceSeconds * 2)) -
            reloadVarianceSeconds;
        reloadDateTime = addSeconds(reloadDateTime, variance);

        // Calculate the number of milliseconds until the resulting time
        const millisecondsUntilReload = differenceInMilliseconds(
            reloadDateTime,
            now,
        );

        // Use the setTimeout function to reload the page at the resulting time
        const timeoutId = setTimeout(() => {
            window.location.reload();
        }, millisecondsUntilReload);

        // Return a cleanup function to clear the timeout
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
}
