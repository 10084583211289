import { nowAsUTC } from "./dateUtils";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import addMilliseconds from "date-fns/addMilliseconds";

/**
 * This timeout class is a workaround for a setTimeout limitation. The setTimeout can handle a maximum timeout value of
 * 2147483647. This class runs for that amount of time and then creates a new timeout object for the remaining time or
 * another maximum if needed. The latest timeout is stored internally and can be cancelled by calling cancel().
 */
export class LongerTimeout {
    private static readonly MAX_TIMEOUT_VALUE = 2147483647;
    private timeout: NodeJS.Timeout;
    private readonly date: Date | number;
    private readonly func: () => void;

    constructor(date: Date | number, func: () => void) {
        if (typeof date === "number") {
            this.date = addMilliseconds(nowAsUTC(), date);
        } else {
            this.date = date;
        }

        this.func = func;
    }

    run(): void {
        if (this.timeout) return;
        this.startTimeout();
    }

    cancel(): void {
        clearTimeout(this.timeout);
    }

    private startTimeout(): void {
        const remainingDifference = differenceInMilliseconds(this.date, nowAsUTC());

        if (remainingDifference > 0) {
            this.timeout = setTimeout(
                () => this.startTimeout(),
                Math.min(remainingDifference, LongerTimeout.MAX_TIMEOUT_VALUE),
            );
        } else {
            this.timeout = setTimeout(this.func, 0);
        }
    }
}
