import Loader from "../Loader/Loader";
import { ErrorPage } from "@castia/sdk";
import React, { PropsWithChildren, ReactElement } from "react";

interface ResponseErrorRetryHandlerProps {
    isLoading: boolean;
    error?: {
        message: string;
        status: number;
    };
    countdown: number;
}

export function ResponseErrorRetryHandler(
    props: PropsWithChildren<ResponseErrorRetryHandlerProps>,
): ReactElement {
    if (props.isLoading) {
        return <Loader />;
    } else if (props.error) {
        // If there is an error while loading the content, it will retry in the useEffect hook of the useRetryingFetch after a timeout. In the meantime, render an error page.
        return (
            <ErrorPage>
                Something went wrong while loading the content. Retrying in{" "}
                {props.countdown} seconds.
            </ErrorPage>
        );
    }

    return <>{props.children}</>;
}
