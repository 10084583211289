/* global SystemJS */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import PluginContext from "./PluginContext";
import useFetchEnabledTemplatePlugins from "hooks/api/plugin/useFetchEnabledTemplatePlugins";
import Loader from "components/Loader/Loader";
import Plugin from "model/Plugin";
import {
    TemplateConfiguration,
    TemplatePluginConfiguration,
    ErrorPage, PluginType,
} from "@castia/sdk";
import * as Sentry from "@sentry/react";

const pluginFetchRetryTimeout = 60000;

/**
 * Extension of the TemplateConfiguration for local usage. It adds the templateIdentifier, which is the concatenation of
 * the pluginId from the pluginService and the key from the TemplateConfiguration.
 */
export interface EnrichedTemplateConfiguration extends TemplateConfiguration {
    templateIdentifier: string;
    resourceDirectory: string;
}

/**
 * List of loaded plugins.
 */
export interface LoadedPlugins {
    templates: EnrichedTemplateConfiguration[];
    findTemplate: (template: string) => EnrichedTemplateConfiguration;
}

/**
 * Provider for the installed plugins. It loads the plugins and exposes the metadata about these plugins so other
 * components can use these to display them.
 * @param props
 * @constructor
 */
function PluginContextProvider(
    props: React.PropsWithChildren<unknown>,
): ReactElement {
    const { response, isLoading, error, refreshData } =
        useFetchEnabledTemplatePlugins();
    const templates = useRef<EnrichedTemplateConfiguration[]>(null);
    const [loaded, isLoaded] = useState(false);
    const currentResponse = useRef<Plugin[]>(null);
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        if (isLoading) {
            isLoaded(false);
        }
        if (response && !isLoading && !error) {
            loadPluginTemplates(
                response.filter((plugin) => plugin.type === PluginType.TEMPLATE),
            ).then((loadedTemplates): void => {
                templates.current = loadedTemplates;
                isLoaded(true);
            });
            currentResponse.current = response;
        }
        let retryTimeout: NodeJS.Timeout;
        let countdownTimeout: NodeJS.Timeout;
        if (!isLoading && !response && error) {
            console.error(error);
            Sentry.captureException(error);
            retryTimeout = setTimeout(() => {
                refreshData();
            }, pluginFetchRetryTimeout);
            setCountdown(60);
            countdownTimeout = setInterval(() => {
                setCountdown((currentCountdown) => currentCountdown - 1);
            }, 1000);
        }

        return () => {
            retryTimeout && clearTimeout(retryTimeout);
            countdownTimeout && clearInterval(countdownTimeout);
        };
    }, [response, isLoading, error]);

    if (isLoading) {
        return <Loader />;
    } else if (error) {
        // If there is an error while loading the plugins, it will retry in the useEffect hook after a timeout. In the meantime, render an error page.
        return (
            <ErrorPage>
                Something went wrong while loading the plugins. Retrying in{" "}
                {countdown} seconds.
            </ErrorPage>
        );
    }

    if (!loaded) {
        return <Loader />;
    }

    const loadedPlugins: LoadedPlugins = {
        templates: templates.current,
        findTemplate: (template: string): EnrichedTemplateConfiguration => {
            return templates.current.find(
                (temp): boolean => temp.templateIdentifier === template,
            );
        },
    };

    return (
        <PluginContext.Provider value={loadedPlugins}>
            {props.children}
        </PluginContext.Provider>
    );
}

/**
 * Import plugins into the runtime via SystemJS.
 * @param installedPlugins
 */
async function loadPluginTemplates(
    installedPlugins: Plugin[],
): Promise<EnrichedTemplateConfiguration[]> {
    const templates: EnrichedTemplateConfiguration[] = [];
    for (const installedPlugin of installedPlugins) {
        try {
            const parcel: { configuration: TemplatePluginConfiguration } =
                await SystemJS.import(installedPlugin.file);
            const templateConfig = parcel.configuration.templateConfiguration;
            if (Array.isArray(templateConfig)) {
                for (const templateConfiguration of templateConfig) {
                    templates.push(
                        enrichTemplateConfiguration(
                            installedPlugin,
                            templateConfiguration,
                        ),
                    );
                }
            } else {
                templates.push(
                    enrichTemplateConfiguration(
                        installedPlugin,
                        templateConfig,
                    ),
                );
            }
        } catch (error) {
            console.error(`Error loading plugin ${installedPlugin.id}`, error);
            Sentry.captureException(error);
        }
    }
    return templates;
}

/**
 * Enrich the Template configuration for local usage. This basically comes down to combining information from the
 * PluginService and the configuration in the plugins source code.
 *
 * It adds a unique identifier to fetch the templates on. By doing this in this way template keys need to only be unique
 * within a plugin.
 *
 * @param plugin
 * @param templateConfiguration
 */
function enrichTemplateConfiguration(
    plugin: Plugin,
    templateConfiguration: TemplateConfiguration,
): EnrichedTemplateConfiguration {
    return {
        templateIdentifier: `${plugin.id}/${templateConfiguration.key}`,
        resourceDirectory: plugin.pluginDirectory || "http://localhost:9100",
        ...templateConfiguration,
    };
}

export default PluginContextProvider;
