/**
 * Creates a Headers object containing the jwt bearer token.
 * @param jwt
 */
function authHeaders(jwt: string): Headers {
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);
    headers.append("Content-Type", "application/json");
    return headers;
}

export default authHeaders;
