import { Schedule } from "../../../model/Schedule";
import { getDatetimeAsCurrentTimezone } from "../../../utils/dateUtils";
import { rrulestr } from "rrule";
import { addYears, subMilliseconds } from "date-fns";
import isWithinInterval from "date-fns/isWithinInterval";
import addMilliseconds from "date-fns/addMilliseconds";
import { ExpandedEvent } from "./ExpandedEvent";
import { isMoreImportant } from "./isMoreImportant";

export function findCurrentEvent(
    schedule: Schedule,
): ExpandedEvent | undefined {
    const now = new Date(Date.now());

    const expandedEvents = schedule.scheduleEvents
        .map((scheduleEvent): ExpandedEvent[] => {
            if (scheduleEvent.repeating) {
                const rule = rrulestr(scheduleEvent.rrule);

                const dates = rule.between(
                    subMilliseconds(now, scheduleEvent.duration),
                    getDatetimeAsCurrentTimezone(addYears(now, 1)),
                    true,
                );

                return dates.map((date) => {
                    return {
                        originalEvent: Object.assign({}, scheduleEvent),
                        date: getDatetimeAsCurrentTimezone(date),
                    };
                });
            }
            return [
                {
                    originalEvent: Object.assign({}, scheduleEvent),
                    date: getDatetimeAsCurrentTimezone(
                        new Date(scheduleEvent.startDateTime),
                    ),
                },
            ];
        })
        .flat();
    const currentEvents = expandedEvents.filter((expandedEvent) => {
        return isWithinInterval(now, {
            start: new Date(expandedEvent.date),
            end: addMilliseconds(
                new Date(expandedEvent.date),
                expandedEvent.originalEvent.duration,
            ),
        });
    });
    const sortedCurrentEvents = currentEvents.sort((a, b) => {
        return isMoreImportant(a, b) ? 1 : -1;
    });

    return sortedCurrentEvents?.[0];
}
