import React, { ReactElement, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import environment from "../../../utils/environment";
import styles from "./Socket.scss";
import { FaPlug } from "react-icons/fa";
import useAuthentication from "../../../hooks/useAuthentication";

export interface WebSocketMessage {
    id: string;
    subject: string;
    event: string;
    payload?: string;
}

interface SocketProps {
    onMessage: (message: WebSocketMessage) => Promise<boolean>;
}

/**
 * Component which keeps a socket connection alive on the player page. This is used for triggering updates.
 * @param props
 * @constructor
 */
function WebSocket(props: SocketProps): ReactElement {
    const [disconnected, setDisconnected] = useState(false);
    const { getToken } = useAuthentication();
    const socket = useRef<Socket>();

    useEffect((): (() => void) => {
        socket.current = io(`${environment.webSocketUrl}/queue`, {
            auth: (cb) => {
                cb({
                    token: getToken(),
                });
            },
            reconnectionDelay: 10000,
            withCredentials: true,
        });

        socket.current.on("connect", (): void => {
            setDisconnected(false);
        });

        socket.current.on("message", async (message: string): Promise<void> => {
            const parsedMessage: WebSocketMessage = JSON.parse(message);
            const result = await props.onMessage(parsedMessage);
            if (result) {
                socket.current.send(
                    JSON.stringify({
                        id: parsedMessage.id,
                    }),
                );
            }
        });

        socket.current.on("disconnect", (): void => {
            setDisconnected(true);
        });

        socket.current.on("error", (): void => {
            setDisconnected(true);
        });

        socket.current.io.on("reconnect_attempt", (): void => {
            setDisconnected(true);
        });

        socket.current.on('connect_error', (err) => {
            // If the socket reconnect after being offline happens before the token was refreshed, the reconnect is
            // seen as successful because there was a connection, but the socket will throw a connect_error event. We
            // have to manually try the reconnection, where we assume that the token was refreshed in the meantime.
            setTimeout(() => {
                socket.current.connect();
            }, 10000);
        });

        socket.current.on("reconnect", (): void => {
            setDisconnected(false);
        });

        return (): void => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, []);

    if (disconnected) {
        return (
            <div className={styles.connectionError}>
                <FaPlug />
            </div>
        );
    }
    return null;
}

export default WebSocket;
