import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "index";

/**
 * Retrieve the root element of where to mount the dashboard application.
 */
function domElementGetter(): HTMLElement {
    return document.getElementById('app');
}

/**
 * Create a single spa react object which defines the Single-SPA required lifecycle functions.
 */
const reactLifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    domElementGetter,
});

export const bootstrap = [
    reactLifecycles.bootstrap,
];

export const mount = [
    reactLifecycles.mount,
];

export const unmount = [
    reactLifecycles.unmount,
];
