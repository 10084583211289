import { ErrorInfo, ReactElement, useEffect } from "react";
import { ErrorPage, useCountdown } from "@castia/sdk";
import environment from "../../utils/environment";
import * as React from "react";

interface ErrorBoundaryErrorPageProps {
    error: Error;
    errorInfo: ErrorInfo;
    retryDelaySeconds: number;
}

export function ErrorBoundaryErrorPage(
    props: ErrorBoundaryErrorPageProps,
): ReactElement {
    const [countdown, setCountdown] = useCountdown([]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (props.error) {
            setCountdown(props.retryDelaySeconds);
            timeout = setTimeout(() => {
                window.location.reload();
            }, props.retryDelaySeconds * 1000);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [props.error]);

    return (
        <ErrorPage>
            <h4>Something went wrong.</h4>
            <span>
                Reloading the player in {countdown}{" "}
                seconds to retry.
            </span>
            <span>If the problem persists, please contact support.</span>
            {environment.environment === "development" && (
                <details open={true} style={{ whiteSpace: "pre-wrap" }}>
                    {props.error && props.error?.toString()}
                    <br />
                    {props.errorInfo?.componentStack}
                </details>
            )}
        </ErrorPage>
    );
}
