import React, { ReactElement, useEffect, useMemo, useState } from "react";
import AuthContext from "./AuthContext";
import jwtDecode from "jwt-decode";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateToken from "../../hooks/api/device/useUpdateToken";
import * as Sentry from "@sentry/react";
import { Navigate } from "react-router-dom";

export interface Auth {
    getDeviceId: () => string;
    getGroups: () => string[];
    getToken: () => string;
    isTokenExpired: (tokenToCheck: string) => boolean;
    wipeTokens: () => void;
}

export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";

/**
 * Provider of the authentication context.
 * This context keeps the JWT up to date in the local storage and provides some utility functions of the Context.
 * The utility functions are present on the useAuthentication hook.
 * @param props
 * @constructor
 */
function AuthContextProvider(
    props: React.PropsWithChildren<unknown>,
): ReactElement {
    const [token, setToken, getToken] = useLocalStorage(TOKEN_KEY);
    const [refreshToken, _, getRefreshToken] = useLocalStorage(REFRESH_TOKEN_KEY);
    const [authenticated, setAuthenticated] = useState(false);
    const { response, sendRequest, error } = useUpdateToken();

    useEffect((): (() => void) => {
        if (!getRefreshToken()) {
            return;
        }

        let tokenToCheck = token;
        // If the response object is set, update tokens in storage and update tokenToCheck for the rest of this effect.
        if (response && response.token) {
            setToken(response.token);
            Sentry.setUser({ deviceId: getDeviceId() });
            setAuthenticated(true);
            tokenToCheck = response.token;
        }

        let refreshTokenTimeout: number;
        if (tokenExpired(tokenToCheck)) {
            updateToken();
        } else {
            const tokenExpiry = getTokenExpiry(tokenToCheck);
            const tokenExpiresIn = tokenExpiry - Date.now();
            refreshTokenTimeout = window.setTimeout((): void => {
                updateToken();
            }, tokenExpiresIn);
            Sentry.setUser({ deviceId: getDeviceId() });
            setAuthenticated(true);
        }
        return (): void => {
            if (refreshTokenTimeout) {
                window.clearTimeout(refreshTokenTimeout);
            }
        };
    }, [response]);

    useEffect(() => {
        let retryTimeout: number;
        // If there is any error other than 410 (410 means the device was removed) retry updating the token after 5 seconds.
        if (error && error.statusCode !== 410) {
            console.error("Token refresh failed! and not 410, scheduling retry.");
            console.error(error);
            Sentry.captureException(error);
            retryTimeout = window.setTimeout(() => {
                updateToken();
            }, 5000);
        }

        return () => {
            if (retryTimeout) {
                window.clearTimeout(retryTimeout);
            }
        };
    }, [error]);

    /**
     * Update the accessToken.
     */
    function updateToken(): void {
        sendRequest({
            refreshToken: getRefreshToken(),
        });
    }

    function wipeTokens(): void {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(TOKEN_KEY);
        location.reload();
    }

    function tokenExpired(tokenToCheck: string): boolean {
        if (!tokenToCheck) {
            return true;
        }
        return Date.now() >= getTokenExpiry(tokenToCheck);
    }

    function getTokenExpiry(tokenToCheck: string): number {
        // @ts-ignore
        const expiry = jwtDecode(tokenToCheck)["exp"];
        return expiry * 1000 - 15000;
    }

    function getDeviceId(): string {
        // @ts-ignore
        return jwtDecode(getToken())["sub"];
    }

    // Create memo of entire auth object because it only contains functions that never change. This prevents re-renders.
    const authWrapper: Auth = useMemo(() => {
        return {
            getDeviceId: getDeviceId,
            getGroups: (): string[] => {
                // @ts-ignore
                return jwtDecode(getToken())["groups"];
            },
            getToken: (): string => {
                return getToken();
            },
            isTokenExpired: tokenExpired,
            wipeTokens: wipeTokens,
        };
    }, []);

    // TODO Don't show error if offline, try loading application instead? Maybe with dummy token?
    // TODO load application if refresh token exists but error with code 1000 occurs
    // If an error was returned on the token refresh, check if it is a 410. If so, delete the tokens, device was deleted.
    if (error) {
        console.error(`Error occured:`, error);
        if (error.statusCode === 410) {
            wipeTokens();
        }
    }

    // Return as a memo to prevent unnecessary rerenders
    return useMemo(() => {
        // If the refresh token is not set, the device is not registered and the user should be redirected to the
        // registration page.
        if (!refreshToken) {
            return <Navigate to="/register" />;
        }

        // While not authenticated, don't render the components within the context. These components expect the
        // authentication to exists.
        if (!authenticated && !(error && refreshToken)) {
            return <></>;
        }

        return (
            <AuthContext.Provider value={authWrapper}>
                {props.children}
            </AuthContext.Provider>
        );
    }, [authWrapper, refreshToken, authenticated, error]);
}

export default AuthContextProvider;
