/* global process */
const environment = {
    environment: process.env.APP_ENV,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    webSocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
    applicationUrl: process.env.REACT_APP_APPLICATION_URL,
    commitShortSha: process.env.REACT_APP_COMMIT_SHORT_SHA,
    version: process.env.REACT_APP_VERSION,
    daemonBaseUrl: process.env.REACT_APP_DAEMON_BASE_URL,
};

export default environment;
