import React, { PropsWithChildren, ReactElement } from "react";
import { useHideCursor } from "../../hooks/useHideCursor";
import styles from "./HideCursor.scss";

export function HideCursor(props: PropsWithChildren<unknown>): ReactElement {
    const hideCursor = useHideCursor();

    if (hideCursor) {
        return <div className={styles.hideCursor}>{props.children}</div>;
    }

    return <>{props.children}</>;
}
