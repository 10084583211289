import useFetchSchedule from "../../../hooks/api/schedule/useFetchSchedule";
import React, { ReactElement, useEffect, useState } from "react";
import { ResponseErrorRetryHandler } from "../../../components/ResponseErrorRetryHandler/ResponseErrorRetryHandler";
import PlayerContainer from "../PlayerContainer/PlayerContainer";
import { NoContent } from "./NoContent";
import Channel from "../../../model/Channel";
import { LongerTimeout } from "../../../utils/LongerTimeout";
import { findCurrentEvent } from "../event/findCurrentEvent";
import { findNextEvent } from "../event/findNextEvent";
import addMilliseconds from "date-fns/addMilliseconds";

interface ScheduleContainer2Props {
    scheduleId: string;
}

export function ScheduleContainer(
    props: ScheduleContainer2Props,
): ReactElement {
    const { response, error, isLoading, countdown } = useFetchSchedule(
        props.scheduleId,
    );
    const [channel, setChannel] = useState<Channel>();
    const [random, setRandom] = useState<number>(Math.random());

    useEffect(() => {
        if (!response) {
            return;
        }

        const currentEvent = findCurrentEvent(response);
        const nextEvent = findNextEvent(response, currentEvent);
        if (currentEvent) {
            setChannel(currentEvent?.originalEvent.channel);
        } else {
            setChannel(
                response.fallbackChannel ? response.fallbackChannel : null,
            );
        }

        const timeTillEndOfCurrentEvent = currentEvent
            ? addMilliseconds(
                  currentEvent.date,
                  currentEvent.originalEvent.duration,
              ).getTime() - Date.now()
            : 0;

        const timeTillNextEvent = nextEvent
            ? nextEvent.date.getTime() - Date.now()
            : 0;
        let timeoutTime = 0;
        if (timeTillEndOfCurrentEvent && timeTillNextEvent) {
            timeoutTime = Math.min(
                timeTillEndOfCurrentEvent,
                timeTillNextEvent,
            );
        } else if (timeTillEndOfCurrentEvent) {
            timeoutTime = timeTillEndOfCurrentEvent;
        } else if (timeTillNextEvent) {
            timeoutTime = timeTillNextEvent;
        } else {
            timeoutTime = 3_600_000;
        }

        const timeout = new LongerTimeout(timeoutTime, () => {
            setRandom(Math.random() + Date.now());
        });
        timeout.run();

        return () => {
            timeout.cancel();
        };
    }, [response, random]);

    return (
        <ResponseErrorRetryHandler
            isLoading={isLoading}
            error={error}
            countdown={countdown}
        >
            {channel ? (
                <PlayerContainer channelId={channel.id} />
            ) : (
                <NoContent />
            )}
        </ResponseErrorRetryHandler>
    );
}
