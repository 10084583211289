import React, { ReactElement } from "react";
import WebSocket, { WebSocketMessage } from "./Socket/WebSocket";
import useFetchDevice from "../../hooks/api/device/useFetchDevice";
import useAuthentication from "../../hooks/useAuthentication";
import { NoContent } from "./components/NoContent";
import { ContentType } from "../../types/ContentType";
import PlayerContainer from "./PlayerContainer/PlayerContainer";
import SceneContainer from "./SceneContainer/SceneContainer";
import { NetworkStatus } from "./components/NetworkStatus";
import { useTakeScreenshots } from "../../hooks/useTakeScreenshots";
import { useDeviceInfoInterval } from "../../hooks/useDeviceInfoInterval";
import { useEventEmitter } from "@castia/sdk";
import { ResponseErrorRetryHandler } from "../../components/ResponseErrorRetryHandler/ResponseErrorRetryHandler";
import { FontFace } from "../../components/FontFace";
import { LocalEvents } from "../../events/LocalEvents";
import { useReloadPageInterval } from "../../hooks/useReloadPageInterval";
import {ScheduleContainer} from "./components/ScheduleContainer";

/**
 * Page showing the player.
 * @constructor
 */
function PlayerPage(): ReactElement {
    const { getDeviceId, wipeTokens } = useAuthentication();
    const { response, isLoading, refreshData, error, countdown } =
        useFetchDevice(getDeviceId());
    useTakeScreenshots();
    useDeviceInfoInterval();
    useReloadPageInterval();
    const eventEmitter = useEventEmitter();

    async function onMessage(message: WebSocketMessage): Promise<boolean> {
        if (message.subject === "device" && message.event === "deleted") {
            wipeTokens();
            return true;
        } else if (
            message.subject === "schedule" &&
            message.event === "changed"
        ) {
            eventEmitter.emit(LocalEvents.CONTENT_UPDATE);
            await refreshData();
            return true;
        } else if (
            message.subject === "device" &&
            message.event === "refresh"
        ) {
            // Wait 5 seconds to reload, so the ack message can be sent back to the websocket server
            const event = new Event("castia:update-check");
            setTimeout(() => window.dispatchEvent(event), 5000);
            return true;
        } else if (
            message.subject === "plugins" &&
            message.event === "reload"
        ) {
            // Wait 5 seconds to reload, so the ack message can be sent back to the websocket server
            setTimeout(() => eventEmitter.emit("plugins:reload"), 5000);
            return true;
        }
        return true;
    }

    function renderContent(
        contentId: string,
        contentType: ContentType,
    ): ReactElement {
        switch (contentType) {
            case "SCHEDULE":
                return <ScheduleContainer scheduleId={response.contentId} />;
            case "CHANNEL":
                return <PlayerContainer channelId={response.contentId} />;
            case "SCENE":
                return <SceneContainer sceneId={response.contentId} />;
        }
    }

    return (
        <>
            <FontFace />
            <NetworkStatus />
            <WebSocket onMessage={onMessage} />
            <ResponseErrorRetryHandler
                isLoading={isLoading}
                countdown={countdown}
                error={error}
            >
                {!isLoading && response && !response.contentId && <NoContent />}
                {!isLoading &&
                    response &&
                    response.contentId &&
                    renderContent(response.contentId, response.contentType)}
            </ResponseErrorRetryHandler>
        </>
    );
}

export default PlayerPage;
