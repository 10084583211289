/**
 * Parse the given string as JSON or return the string if it is not json.
 * @param value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseJsonOrText(value: string): any {
    try {
        return JSON.parse(value);
    } catch(error) {
        return value;
    }
}

export default parseJsonOrText;
