import React from "react";
import {FunctionComponent, ReactElement} from "react";
import styles from "./LoaderCheckMark.scss";

interface LoaderCheckmarkProps {
    complete: boolean;
}

const LoaderCheckMark: FunctionComponent<LoaderCheckmarkProps> = function (props: LoaderCheckmarkProps): ReactElement<LoaderCheckmarkProps> {
    let checkClasses;
    let fillClasses;

    if (props.complete) {
        checkClasses = [styles.check, styles.checkComplete, styles.success].join(' ');
        fillClasses = [styles.fill, styles.fillComplete, styles.success].join(' ');
    } else {
        checkClasses = styles.check;
        fillClasses = styles.fill;
    }

    return <div className={styles.checkmark}>
        <svg x="0px" y="0px" viewBox="0, 0, 100, 100">
            <g transform="">
                <circle className={styles.path} fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
                <circle className={fillClasses} fill="none" stroke="#7DB0D5" strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="44"/>
                <polyline className={checkClasses} fill="none" stroke="#7DB0D5" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" points="70,35 45,65 30,52  "/>
            </g>
        </svg>
    </div>;
};

export default LoaderCheckMark;
