import {useContext} from "react";
import PluginContext from "components/Plugin/PluginContext/PluginContext";
import {LoadedPlugins} from "components/Plugin/PluginContext/PluginContextProvider";

/**
 * Hook to use the plugins context.
 */
function usePlugins(): LoadedPlugins {
    return useContext(PluginContext);
}

export default usePlugins;
