import environment from "../utils/environment";

interface DaemonInfo {
    appVersion: string;
    appVersionName: string;
    osVersion: string;
    osVersionName: string;
    osName: string;
    kernelVersion: string;
    uptime: number;
    daemonAvailable: boolean;
    ansibleVersion: string;
    wireGuardPeerIp: string;
}

export async function getDaemonInfo(): Promise<DaemonInfo> {
    return fetch(`${environment.daemonBaseUrl}/about`, {})
        .then(async (response) => {
            return response.json();
        })
        .catch((error) => {
            console.error("Error gathering daemon info", error);
            return null;
        });
}
