/* eslint @typescript-eslint/no-explicit-any: 0 */
import useAuthentication from "hooks/useAuthentication";
import { useEventEmitter } from "@castia/sdk";
import parseJsonOrText from "../utils/parseJsonOrText";
import * as Sentry from "@sentry/react";

export interface MultipartRequest {
    sendRequest: (
        payload?: any,
        urlValues?: UrlValue[],
    ) => Promise<string | void>;
}

interface UrlValue {
    key: string;
    value: string;
}

function replaceValues(url: string, urlValues: UrlValue[]): string {
    let resultUrl = url;
    urlValues.forEach((urlValue): void => {
        resultUrl = resultUrl.replace(`$${urlValue.key}`, urlValue.value);
    });
    return resultUrl;
}

/**
 * Gives a function to call the API to persist data. Sets the latest response variables in the state.
 *
 * The url may contain placeholders for values which are not known at the time of calling the hook. These values must
 * be prefixed with a '$' sign. To set the values, pass them in as the second argument of the sendRequest operation
 * (without prefixing them with $ in that list).
 * @param url
 * @param successEvent
 */
export function useStatelessFetchMultipartPost(
    url: string,
    successEvent?: string,
): MultipartRequest {
    const auth = useAuthentication();

    const eventEmitter = useEventEmitter();

    async function callAPI(
        payload?: FormData,
        urlValues?: UrlValue[],
    ): Promise<string | void> {
        const jwt = await auth.getToken();
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + jwt);

        const request: RequestInit = {
            method: "POST",
            headers: headers,
        };

        if (payload) {
            request.body = payload;
        }

        let callUrl = url;
        if (urlValues) {
            callUrl = replaceValues(callUrl, urlValues);
        }

        try {
            const apiResponse = await fetch(callUrl, request);
            if (!apiResponse.ok) {
                const errorResponseBody = parseJsonOrText(
                    await apiResponse.text(),
                ) as any;
                const newError = {
                    message:
                        errorResponseBody.message || apiResponse.statusText,
                    statusCode: apiResponse.status,
                    timestamp: new Date().getTime(),
                };
                Sentry.captureException(newError);
                return;
            }
            const text = await apiResponse.text();
            // Set the response value to OK if the response is empty to ensure it always has a value when the request
            // was successful.
            const responseValue = parseJsonOrText(text) || "OK";
            // If a success event is set, emit it.
            successEvent && eventEmitter.emit(successEvent);
            return responseValue;
        } catch (requestError) {
            Sentry.captureException(requestError);
        }
    }

    return { sendRequest: callAPI };
}
