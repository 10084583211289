import React, { ReactElement } from "react";
import { TenantProfileContext } from "tenant-profile/TenantProfileContext";
import { usePublicTenantProfile } from "hooks/api/tenant-profile/usePublicTenantProfile";
import { ResponseErrorHandler } from "@castia/sdk";
import { Helmet } from "react-helmet";
import favicon from "../../assets/favicon.png";

/**
 * Provider for the Tenant Profile (white-labeling) context.
 * @param props
 * @constructor
 */
export function TenantProfileContextProvider(
    props: React.PropsWithChildren<unknown>,
): ReactElement {
    const tenantProfileContext = usePublicTenantProfile();

    if (tenantProfileContext)
        return (
            <ResponseErrorHandler
                isLoading={tenantProfileContext.isLoading}
                error={tenantProfileContext.error}
                retryAction={() => tenantProfileContext.refreshData()}
            >
                {tenantProfileContext.response && (
                    <>
                        <Helmet>
                            <link
                                href={
                                    tenantProfileContext.response.theme.cssFile
                                }
                                rel="stylesheet"
                            />
                            {tenantProfileContext.response.theme?.favicon ? (
                                <link
                                    rel="icon"
                                    href={
                                        tenantProfileContext.response.theme
                                            .favicon
                                    }
                                />
                            ) : (
                                <link rel="icon" href={favicon} />
                            )}
                        </Helmet>
                        <TenantProfileContext.Provider
                            value={tenantProfileContext.response}
                        >
                            {props.children}
                        </TenantProfileContext.Provider>
                    </>
                )}
            </ResponseErrorHandler>
        );
}
