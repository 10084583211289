import * as React from "react";
import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import AuthContextProvider from "../../services/auth/AuthContextProvider";
import PluginContextProvider from "../Plugin/PluginContext/PluginContextProvider";
import { EventContextProvider } from "../../core/events/EventContextProvider";
import { AppContextProvider } from "../../core/context/AppContextProvider";

/**
 * Private route which requires the device to be registered to display.
 */
function PrivateRoute(): ReactElement {
    return (
        <EventContextProvider>
            <AuthContextProvider>
                <AppContextProvider>
                    <PluginContextProvider>
                        <Outlet />
                    </PluginContextProvider>
                </AppContextProvider>
            </AuthContextProvider>
        </EventContextProvider>
    );
}

export default PrivateRoute;
