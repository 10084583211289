import { TransitionConfig } from "./transitions";

export const crossFadeTransition: TransitionConfig = {
    mode: "in-out",
    styles: (state: string) => {
        let opacity = 0;
        switch (state) {
            case "entering":
                opacity = 0;
                break;
            case "entered":
                opacity = 1;
                break;
            case "exiting":
                opacity = 0;
                break;
            case "exited":
                opacity = 0;
                break;
        }

        return {
            opacity: opacity,
            willChange: "opacity",
        };
    },
};
