import { CSSProperties } from "react";
import { slideFromLeftTransition } from "./slideFromLeftTransition";
import { slideFromRightTransition } from "./slideFromRightTransition";
import { slideFromBottomTransition } from "./slideFromBottomTransition";
import { slideFromTopTransition } from "./slideFromTopTransition";
import { crossFadeTransition } from "./crossFadeTransition";
import { fadeInOutTransition } from "./fadeInOutTransition";

export interface TransitionProps {
    templateKey: string;
    config: TransitionConfig;
}

export interface TransitionConfig {
    styles: (state: string) => CSSProperties;
    mode: "out-in" | "in-out";
}

export const transitions: { [index: string]: TransitionConfig } = {
    slideFromLeft: slideFromLeftTransition,
    slideFromRight: slideFromRightTransition,
    slideFromBottom: slideFromBottomTransition,
    slideFromTop: slideFromTopTransition,
    crossFade: crossFadeTransition,
    fadeInOut: fadeInOutTransition,
};
