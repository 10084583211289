import Channel from "model/Channel";
import environment from "../../../utils/environment";
import { RetryingFetchData, useRetryingFetch } from "../../useRetryingFetch";

/**
 * Hook to fetch a channel with the given id.
 * @param channelId
 */
function useFetchChannel(channelId: string): RetryingFetchData<Channel> {
    return useRetryingFetch<Channel>(
        `${environment.apiBaseUrl}/api/v1/channels/${channelId}`,
    );
}

export default useFetchChannel;
