import React, { ReactElement, useEffect, useRef, useState } from "react";
import ChannelSceneDTO from "../../../model/ChannelSceneDTO";
import environment from "../../../utils/environment";
import PluginTemplateComponent from "../../../components/Plugin/PluginTemplateComponent/PluginTemplateComponent";
import usePlugins from "../../../hooks/usePlugins";
import { useForceUpdate } from "../../../hooks/useForceUpdate";
import { deepMerge } from "../../../core/util/deepMerge";

interface PlayerProps {
    scenes: ChannelSceneDTO[];
    transitionType?: string;
}

/**
 * The player component which cycles through the different scenes which are passed into it.
 * @param props
 * @constructor
 */
function Player(props: PlayerProps): ReactElement {
    const [currentScene, setCurrentScene] = useState(0);
    const nextSceneRef = useRef(0);
    const [iteration, forceUpdate] = useForceUpdate();
    const { findTemplate } = usePlugins();
    const scene = props.scenes[currentScene].scene;
    const template = findTemplate(scene.template);

    useEffect((): void => {
        setCurrentScene(0);
    }, [props]);

    useEffect((): (() => void) => {
        const duration = template.calculatedDuration
            ? template.calculatedDuration(scene.settings)
            : props.scenes[currentScene].duration;

        const nextSceneTimer = setTimeout((): void => {
            // If the next scene is the same, increase the iteration to allow the scene to handle this situation via
            // the useOnSceneRestart hook.
            if (currentScene === nextSceneRef.current) {
                forceUpdate();
            }
            setCurrentScene(nextSceneRef.current);
        }, duration * 1000);

        const preNextSceneTime = setTimeout(async (): Promise<void> => {
            const numberOfScenes = props.scenes.length;

            let nextSceneNumber;
            if (currentScene < numberOfScenes - 1) {
                nextSceneNumber = currentScene + 1;
            } else {
                nextSceneNumber = 0;
            }
            // Set nextSceneRef to the next scene according to the order. If none of the others should be shown, it will show this one.
            nextSceneRef.current = nextSceneNumber;
            for (let i = 0; i < numberOfScenes; i++) {
                let num = i + nextSceneNumber;
                if (num > numberOfScenes - 1) {
                    num = num - numberOfScenes;
                }

                const nextScene = props.scenes[num].scene;
                const nextTemplate = findTemplate(nextScene.template);

                const settings = {
                    title: nextScene.title,
                    custom: deepMerge(
                        {},
                        nextTemplate.defaultSettings || {},
                        nextScene.settings.custom,
                    ),
                };
                if (!nextTemplate.shouldShowInNextIteration) {
                    nextSceneRef.current = num;
                    // If scene should be shown, break off for-loop and end the function.
                    return;
                }
                try {
                    const shouldShowInNextIteration =
                        await nextTemplate.shouldShowInNextIteration(
                            environment,
                            settings,
                        );

                    if (shouldShowInNextIteration) {
                        nextSceneRef.current = num;
                        // If scene should be shown, break off for-loop and end the function.
                        return;
                    }
                } catch (error) {
                    // If an error occurs when calling the shouldShowInNextIteration, just continue to the next scene?
                    continue;
                }
            }
        }, duration * 1000 - 5000);

        return (): void => {
            clearTimeout(nextSceneTimer);
            clearTimeout(preNextSceneTime);
        };
    }, [currentScene, iteration]);

    if (!template) {
        return <div>Unknown template {scene.template}</div>;
    }

    const settings = {
        title: scene.title,
        custom: deepMerge(
            {},
            template.defaultSettings || {},
            scene.settings.custom,
        ),
    };

    return (
        <PluginTemplateComponent
            transitionType={props.transitionType}
            template={template.templateIdentifier}
            resourceDirectory={template.resourceDirectory}
            layoutId={scene.layout}
            environment={environment}
            templateProps={{
                editable: false,
                content: scene.content,
                settings: settings,
                iteration: iteration,
            }}
            sceneId={props.scenes[currentScene].scene.id}
        />
    );
}

export default Player;
