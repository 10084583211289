import {TransitionConfig} from "./transitions";

export const slideFromRightTransition: TransitionConfig = {
    mode: "in-out",
    styles: (state: string) => {
        let transition = "translate3d(0, 0, 0)";
        switch (state) {
            case "entering":
                transition = "translate3d(100%, 0, 0)";
                break;
            case "entered":
                transition = "translate3d(0, 0, 0)";
                break;
            case "exiting":
                transition = "translate3d(-100%, 0, 0)";
                break;
            case "exited":
                transition = "translate3d(-100%, 0, 0)";
                break;
        }
        return {
            "transform": transition,
            willChange: "transform",
        };
    },
};
