// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

// TODO ensure SW starts on hard reload (so from cold-cache)

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config): void {
  // Set to always be true if you want to test locally.
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.REACT_APP_PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        console.info('SWR: You are running on localhost!');
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.info(
            'SWR: This web app is being served cache-first by a service worker.'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  } else {
      console.info('SWR: There is no serviceWorker available. Running in online-only mode.');
  }
}
function registerValidSW(swUrl: string, config?: Config) {
  console.info('SWR: Registering SW from ' + swUrl);
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      window.addEventListener("castia:update-check", () => {
        console.info('SWR: Checking for update now! If there is one the application will refresh automatically!');
        registration.update();
      });

      if (registration.active && !navigator.serviceWorker.controller) {
        // Perform a soft reload to load everything from the SW and get
        // a consistent set of resources.
        console.info('SWR: Registration active, reloading.');
        window.location.reload();
      }

      registration.onupdatefound = () => {
        console.info('SWR: ServiceWorker registration update found!');
        const installingWorker = registration.installing;

        if (installingWorker == null) {
          console.warn('SWR: InstallingWorker is null.');
          return;
        }
        installingWorker.onstatechange = () => {

          console.info('SWR: Installing Worker State: ' + installingWorker.state);
          if (installingWorker.state === 'activated') {
            console.info('SWR: Installing worker is now activated, reloading the page!');
            // if (refreshing) return;
            // refreshing = true;
            // window.location.reload();
          }
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.info(
                'SWR: New content is available and will be used when all ' +
                  'tabs for this page are closed.'
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
              // self.skipWaiting();
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.info('SWR: Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      console.info('SWR: Fetched swUrl');
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        console.warn('SWR: Service worker not valid, unregistering it ASAP.');
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        console.info('SWR: Service worker found. Proceed as normal.');
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.info('SWR: No internet connection found. App is running in offline mode.');
    });
}

export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

// Any other custom service worker logic can go here.
navigator.serviceWorker?.addEventListener('controllerchange', () => {
  console.info('SWR: Service worker controller changed.');
});

let refreshing = false;
navigator.serviceWorker?.addEventListener('controllerchange',
    function() {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    }
);
