import "core-js/stable";
import "regenerator-runtime/runtime";
import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { ErrorInfo, ReactElement } from "react";

import "@castia/sdk/dist/sdk.css";
import "./static/bootstrap.css";
import "./scss/styles.scss";

import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PlayerPage from "./pages/PlayerPage/PlayerPage";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryErrorPage } from "./pages/ErrorBoundaryErrorPage/ErrorBoundaryErrorPage";
import { TenantProfileContextProvider } from "./tenant-profile/TenantProfileContextProvider";
import { HideCursor } from "./components/HideCursor/HideCursor";

serviceWorkerRegistration.register();

interface RootProps {
    hasError: boolean;
}

interface RootState {
    error?: Error;
    errorInfo?: ErrorInfo;
}

export default class Root extends React.Component<RootProps, RootState> {
    public constructor(props: RootProps) {
        super(props);
        this.state = {};
    }

    public componentDidCatch(error: Error, info: ErrorInfo): void {
        this.setState({
            error: error,
            errorInfo: info,
        });
    }

    public render(): ReactElement {
        if (this.state.errorInfo) {
            Sentry.captureException(this.state.error);

            return (
                <ErrorBoundaryErrorPage
                    error={this.state.error}
                    errorInfo={this.state.errorInfo}
                    retryDelaySeconds={60}
                />
            );
        }

        return (
            <TenantProfileContextProvider>
                <Router>
                    <HideCursor>
                        <Routes>
                            <Route
                                path="/register"
                                element={<RegistrationPage />}
                            />
                            <Route path="/" element={<PrivateRoute />}>
                                <Route path="/" element={<PlayerPage />} />
                            </Route>
                        </Routes>
                    </HideCursor>
                </Router>
            </TenantProfileContextProvider>
        );
    }
}
