import * as React from "react";
import { AppContext, AppVariant } from "./AppContext";
import { useCheckDaemon } from "../../hooks/daemon-integration/useCheckDaemon";
import { PropsWithChildren, ReactElement } from "react";

export function AppContextProvider(
    props: PropsWithChildren<unknown>,
): ReactElement {
    const isDaemon = useCheckDaemon();

    let appVariant = AppVariant.WEB;
    if (window.Castia) {
        appVariant = AppVariant.ELECTRON;
    } else if (isDaemon) {
        appVariant = AppVariant.DAEMON;
    }

    return (
        <AppContext.Provider
            value={{
                appVariant: appVariant,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}
