import {FetchData, useFetch} from "@castia/sdk";
import {useEffect, useState} from "react";
import * as Sentry from "@sentry/react";

export interface RetryingFetchData<T> extends FetchData<T> {
    countdown: number;
}

export function useRetryingFetch<T>(url: string, retryAfter = 30): RetryingFetchData<T> {
    const fetchData = useFetch<T>(url);
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        let retryTimeout: NodeJS.Timeout;
        let countdownTimeout: NodeJS.Timeout;
        if (fetchData.error && !fetchData.isLoading) {
            console.error(fetchData.error);
            Sentry.captureException(fetchData.error);
            retryTimeout = setTimeout(() => {
                fetchData.refreshData();
            }, retryAfter * 1000);
            setCountdown(retryAfter);
            countdownTimeout = setInterval(() => {
                setCountdown((currentCountdown) => currentCountdown - 1);
            }, 1000);
        }

        return () => {
            retryTimeout && clearTimeout(retryTimeout);
            countdownTimeout && clearInterval(countdownTimeout);
        };
    }, [fetchData.error, fetchData.isLoading]);

    return {...fetchData, countdown};
}
