import {TransitionConfig} from "./transitions";

export const fadeInOutTransition: TransitionConfig = {
    mode: "out-in",
    styles: (state: string) => {
        return {
            opacity: state === "entered" ? 1 : 0,
        };
    }
};
