import environment from "../utils/environment";

export interface DaemonScreenshotResponse {
    screenshots: string[];
}

export async function takeScreenshot(): Promise<DaemonScreenshotResponse> {
    return fetch(`${environment.daemonBaseUrl}/screenshot`, {})
        .then(async (response) => {
            return response.json();
        })
        .catch((error) => {
            console.error("Error taking screenshot", error);
            return null;
        });
}
