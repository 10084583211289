import useLocalStorage from "./useLocalStorage";

export const CONTENT_RETRIEVAL_TIMESTAMP_KEY = "content-retrieval-timestamp";

export function useLastContentRetrievalTime(): [() => void, () => Date] {
    const [, setValue, getValue] = useLocalStorage(
        CONTENT_RETRIEVAL_TIMESTAMP_KEY,
    );

    function updateTimestamp() {
        setValue(new Date().getTime().toString());
    }

    function getDate() {
        return new Date(Number(getValue()));
    }

    return [updateTimestamp, getDate];
}
