import { Schedule } from "../../../model/Schedule";
import { getDatetimeAsCurrentTimezone } from "../../../utils/dateUtils";
import addMilliseconds from "date-fns/addMilliseconds";
import { rrulestr } from "rrule";
import { addYears, isAfter, subMilliseconds } from "date-fns";
import isWithinInterval from "date-fns/isWithinInterval";
import { ExpandedEvent } from "./ExpandedEvent";
import { isMoreImportant } from "./isMoreImportant";

export function findNextEvent(
    schedule: Schedule,
    currentEvent?: ExpandedEvent,
): ExpandedEvent {
    /*
    find the next event that should be started, so we can set a timeout for when it should begin.
    This event can either be one for which we are already in the window, but be overlapping with the higher priority event that we selected before,
    or it can be an event that is scheduled further in the future, after the current one has ended.

    So we need to find the first one of the following:
    - An event that starts during the currentEvent but has a higher priority.
    - An event that has a lower priority and overlaps with the currentEvent but continues after the currentEvent has ended.
    - The first events (based on datetime) after the current one that don't overlap
    - Combinations of the above, resolving based on priority.
     */
    const now = new Date(Date.now());
    const currentEventEndTime = currentEvent
        ? addMilliseconds(
              currentEvent.date,
              currentEvent.originalEvent.duration,
          )
        : null;

    const expandedEvents = schedule.scheduleEvents
        .map((scheduleEvent): ExpandedEvent[] => {
            if (scheduleEvent.repeating) {
                const rule = rrulestr(scheduleEvent.rrule);
                const dates = rule.between(
                    subMilliseconds(now, scheduleEvent.duration),
                    getDatetimeAsCurrentTimezone(addYears(now, 1)),
                );

                return dates.map((date) => {
                    return {
                        originalEvent: Object.assign({}, scheduleEvent),
                        date: getDatetimeAsCurrentTimezone(date),
                    };
                });
            }
            return [
                {
                    date: getDatetimeAsCurrentTimezone(
                        new Date(scheduleEvent.startDateTime),
                    ),
                    originalEvent: Object.assign({}, scheduleEvent),
                },
            ];
        })
        .flat();

    // Get the events in the current window (excluding the currentEvent) for which the end date is further in the future than the currentEvent.
    // The start date of the found events should be considered to be at the currentEventEndTime as they would start immediately after the currentEvent.
    // Also get the future events and then sort the list on startDate.
    const potentialFutureEvents = expandedEvents
        .filter((expandedEvent) => {
            if (
                isWithinInterval(now, {
                    start: expandedEvent.date,
                    end: addMilliseconds(
                        expandedEvent.date,
                        expandedEvent.originalEvent.duration,
                    ),
                }) &&
                currentEventEndTime &&
                isAfter(
                    addMilliseconds(
                        expandedEvent.date,
                        expandedEvent.originalEvent.duration,
                    ),
                    currentEventEndTime,
                ) &&
                isMoreImportant(currentEvent, expandedEvent)
            ) {
                // Set the found event startDateTime to the currentEventEndTime for sorting purposes. Practically it can only start once the current event ends.
                expandedEvent.date = currentEventEndTime;
                return true;
            }

            return isAfter(expandedEvent.date, now);
        })
        .sort((a, b) => {
            // Compare by datetime
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;

            return isMoreImportant(a, b) ? 1 : -1;
        });

    return potentialFutureEvents?.[0];
}
