import React from "react";
import {FunctionComponent, ReactElement} from "react";
import styles from "./RegistrationCode.scss";

interface RegistrationCodeProps {
    code: string;
}

const RegistrationCode: FunctionComponent<RegistrationCodeProps> =
    function (props: RegistrationCodeProps): ReactElement<RegistrationCodeProps> {
        return <div className={styles.registrationCode}>
            {props.code.split('').map((character, index): ReactElement => {
                return <span key={index} className={styles.character}>{character}</span>;
            })}
        </div>;
    };

export default RegistrationCode;
