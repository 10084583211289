import { Device } from "../../../model/Device";
import environment from "../../../utils/environment";
import { useLastContentRetrievalTime } from "../../useLastContentRetrievalTime";
import { RetryingFetchData, useRetryingFetch } from "../../useRetryingFetch";

/**
 * Hook to retrieve device details.
 * @param deviceId
 */
function useFetchDevice(deviceId: string): RetryingFetchData<Device> {
    const [updateContentRetrievalTime] = useLastContentRetrievalTime();

    const result = useRetryingFetch<Device>(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}`,
    );

    updateContentRetrievalTime();

    return result;
}

export default useFetchDevice;
