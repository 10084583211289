import endOfWeek from "date-fns/endOfWeek";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

export function createDateAsUTC(date: Date): Date {
    return new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    );
}

/**
 * This function returns a date that takes the UTC date and time of the input and assumes it is in the current timezone.
 * So if the input time is 15:00 UTC (or 17:00 UTC+2), and the current timezone is UTC+2, the output will be 15:00 UTC+2.
 * @param date
 */
export function getDatetimeAsCurrentTimezone(date: Date): Date {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );
}

export function nowAsUTC(): Date {
    return createDateAsUTC(new Date(Date.now()));
}

export function dateToMillisecondsFromMidnightUTC(date: Date): number {
    const minutes = date.getUTCHours() * 60 + date.getUTCMinutes();

    return minutes * 60000 + date.getUTCSeconds() * 1000 + date.getUTCMilliseconds();
}

export function dateToMillisecondsSinceStartOfWeek(date: Date): number {
    const dayOfWeek = date.getUTCDay(); // 0=sunday
    const daysPassedThisWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    return daysPassedThisWeek * 86400000 + dateToMillisecondsFromMidnightUTC(date);
}

export function timeUntilEndOfWeek(date: Date): number {
    return differenceInMilliseconds(endOfWeek(date, {weekStartsOn: 1}), date);
}
