import { useEffect } from "react";
import { useLastContentRetrievalTime } from "./useLastContentRetrievalTime";
import { useUpdateDeviceData } from "./api/device/useUpdateDeviceData";
import useAuthentication from "./useAuthentication";
import environment from "../utils/environment";
import { useAppContext } from "../core/context/useAppContext";
import { AppVariant } from "../core/context/AppContext";
import { getDaemonInfo } from "../daemon-integration/getDaemonInfo";

// Send device info every 5 minutes.
const DEVICE_INFO_INTERVAL = 300000;
const PLAYER_START_TIME_KEY = "PLAYER_START_TIME";

export function useDeviceInfoInterval(): void {
    const { getDeviceId } = useAuthentication();
    const { appVariant } = useAppContext();
    const { sendRequest } = useUpdateDeviceData(getDeviceId());
    const [, getLastContentRetrievalTime] = useLastContentRetrievalTime();

    function getBrowserDeviceInfo() {
        const startTime = sessionStorage.getItem(PLAYER_START_TIME_KEY);

        if (!startTime) {
            sessionStorage.setItem(
                PLAYER_START_TIME_KEY,
                new Date(
                    new Date().getTime() - performance.now(),
                ).toISOString(),
            );
        }

        return {
            screenResolution: `${
                window.screen.width * window.devicePixelRatio
            }x${window.screen.height * window.devicePixelRatio}`,
            userAgent: window.navigator.userAgent,
            playerStartTime: new Date(
                sessionStorage.getItem(PLAYER_START_TIME_KEY),
            ),
            playerReloadTime: new Date(
                new Date().getTime() - performance.now(),
            ),
            lastContentRetrievalTime: getLastContentRetrievalTime(),
            playerVersion: environment.version,
            playerCommitShortSha: environment.commitShortSha,
            playerDateTime: new Date(),
            playerTimezoneOffset: new Date().getTimezoneOffset(),
        };
    }

    async function gatherAndSendDeviceInfo() {
        let deviceInfo = getBrowserDeviceInfo();

        // Check if a device-adapter exists with a getDeviceInfo function. If so, append the extra data to the browser data.
        if (appVariant === AppVariant.ELECTRON && window.Castia.getDeviceInfo) {
            const additionalInfo = await window.Castia.getDeviceInfo();
            deviceInfo = {
                ...deviceInfo,
                ...(typeof additionalInfo === "string"
                    ? JSON.parse(additionalInfo)
                    : additionalInfo),
            };
        } else if (appVariant === AppVariant.DAEMON) {
            const additionalInfo = await getDaemonInfo();
            deviceInfo = {
                ...deviceInfo,
                ...additionalInfo,
            };
        }

        await sendRequest(deviceInfo);
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            gatherAndSendDeviceInfo();
        }, DEVICE_INFO_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    });
}
