import environment from "../../../utils/environment";
import { Schedule } from "../../../model/Schedule";
import {RetryingFetchData, useRetryingFetch} from "../../useRetryingFetch";

/**
 * Hook to fetch a channel with the given id.
 * @param scheduleId
 */
export function useFetchSchedule(scheduleId: string): RetryingFetchData<Schedule> {
    return useRetryingFetch<Schedule>(
        `${environment.apiBaseUrl}/api/v2/schedules/${scheduleId}?futureOnly=true`,
    );
}

export default useFetchSchedule;
