/* eslint @typescript-eslint/no-explicit-any: 0 */
import useAuthentication from "hooks/useAuthentication";
import {useState} from "react";
import authHeaders from "utils/authHeaders";
import parseJsonOrText from "utils/parseJsonOrText";
import * as Sentry from "@sentry/react";

export const NETWORK_ERROR = 1000;

export interface ApiError {
    message: string;
    statusCode: number;
    timestamp: number;
}

export interface PersistData {
    response: any;
    error: ApiError;
    isLoading: boolean;
    sendRequest: (payload?: any, urlValues?: UrlValue[]) => Promise<any | void>;
}

interface UrlValue {
    key: string;
    value: string;
}

function replaceValues(url: string, urlValues: UrlValue[]): string {
    let resultUrl = url;
    urlValues.forEach((urlValue): void => {
        resultUrl = resultUrl.replace(`$${urlValue.key}`, urlValue.value);
    });
    return resultUrl;
}

/**
 * Gives a function to call the API to persist data. Sets the latest response variables in the state.
 *
 * The url may contain placeholders for values which are not known at the time of calling the hook. These values must
 * be prefixed with a '$' sign. To set the values, pass them in as the second argument of the sendRequest operation
 * (without prefixing them with $ in that list).
 * @param url
 * @param method
 * @param authenticated If set to false, the jwt header will not be included.
 */
function useFetchPersist(url: string, method: string, authenticated = true): PersistData {
    const auth = useAuthentication();

    const [response, setResponse] = useState(null);
    const [error, setError] = useState<ApiError>(null);
    const [isLoading, setIsLoading] = useState(false);

    async function callAPI(payload?: any, urlValues?: UrlValue[]): Promise<any | void> {
        setIsLoading(true);

        let request: RequestInit;
        if (authenticated) {
            const jwt = await auth.getToken();
            request = {
                method: method,
                headers: authHeaders(jwt)
            };
        } else {
            request = {
                method: method,
                headers: {"Content-Type": "application/json"}
            };
        }

        if (payload) {
            request.body = JSON.stringify(payload);
        }

        let callUrl = url;
        if (urlValues) {
            callUrl = replaceValues(callUrl, urlValues);
        }

        try {
            const apiResponse = await fetch(callUrl, request);
            if (!apiResponse.ok) {
                setError({
                    statusCode: apiResponse.status,
                    message: apiResponse.statusText,
                    timestamp: new Date().getTime()
                });
                return;
            }
            const text = await apiResponse.text();
            // Set the response value to OK if the response is empty to ensure it always has a value when the request
            // was successful.
            const responseValue = parseJsonOrText(text) || "OK";
            setResponse(responseValue);
            setIsLoading(false);
            setError(null);
            return responseValue;
        } catch (apiError) {
            console.error("useFetchPersist catch error: " + apiError.message);
            console.error(apiError);
            Sentry.captureException(apiError);
            setError({
                // Custom status code for network errors. Fetch never throws an error on an http status error, so in
                // this catch there is always a network issue.
                statusCode: NETWORK_ERROR,
                message: apiError.toString(),
                timestamp: new Date().getTime()
            });
            setIsLoading(false);
        }
    }

    return {response, error, isLoading, sendRequest: callAPI};
}

export default useFetchPersist;
