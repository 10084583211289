import environment from "../../../utils/environment";
import {StatelessPersistData, useStatelessFetchPersist} from "../../useStatelessFetchPersist";

export function useUpdateDeviceData(deviceId: string): StatelessPersistData {
    return useStatelessFetchPersist(
        `${environment.apiBaseUrl}/api/v1/devices/${deviceId}/info`,
        "POST",
        true,
    );
}
