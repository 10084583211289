import React, { PropsWithChildren, ReactElement } from "react";
import { TransitionProps } from "./transitions";
import { SwitchTransition, Transition } from "react-transition-group";
import styles from "./MainTransition.scss";

export function MainTransition(
    props: PropsWithChildren<TransitionProps>,
): ReactElement {
    return (
        <SwitchTransition mode={props.config.mode}>
            <Transition
                key={props.templateKey}
                timeout={800}
                unmountOnExit
                mountOnEnter
            >
                {(state) => {
                    return (
                        <div
                            className={styles.transition}
                            style={{
                                position: "absolute",
                                display: state === "exited" ? "none" : "block",
                                ...props.config.styles(state),
                            }}
                        >
                            {props.children}
                        </div>
                    );
                }}
            </Transition>
        </SwitchTransition>
    );
}
