import { useRef } from "react";

interface UseLocalStorageOptions {
    initialValue?: string;
    isJson?: boolean;
}

/**
 * Retrieves local storage and stores it in a ref
 * @param key
 * @param options
 */
function useLocalStorage(
    key: string,
    options: UseLocalStorageOptions = {},
): [string, (value: string) => void, () => string] {
    const storedValue = useRef<string>(getValue());

    function getValue(): string {
        try {
            const item = window.localStorage.getItem(key);
            if (!item) {
                return options.initialValue;
            }
            return options.isJson ? JSON.parse(item) : item;
        } catch (error) {
            // If error also return initialValue
            return options.initialValue;
        }
    }

    function setValue(value: string): void {
        try {
            if (!value) {
                window.localStorage.removeItem(key);
                return;
            }

            storedValue.current = value;
            window.localStorage.setItem(
                key,
                options.isJson ? JSON.stringify(value) : value,
            );
        } catch (error) {
            console.error(error);
        }
    }

    return [storedValue.current, setValue, getValue];
}

export default useLocalStorage;
