import { ExpandedEvent } from "./ExpandedEvent";

export function isMoreImportant(
    event: ExpandedEvent,
    eventToCompare: ExpandedEvent,
): boolean {
    // If the eventToCompare is not repeating, but the first one is, it is always more important.
    if (!eventToCompare.originalEvent.repeating && event.originalEvent.repeating) {
        return true;
    }
    if (
        eventToCompare.originalEvent.frequency < event.originalEvent.frequency
    ) {
        // If frequency is lower, the eventToCompare is more important.
        return true;
    }
    if (
        eventToCompare.originalEvent.frequency > event.originalEvent.frequency
    ) {
        // If frequency is higher, the eventToCompare is less important.
        return false;
    }
    // If neither, we compare the interval instead, where a higher interval is more important.
    if (eventToCompare.originalEvent.interval > event.originalEvent.interval) {
        // If interval is higher, it occurs less often, so it is more important.
        return true;
    }
    if (eventToCompare.originalEvent.interval < event.originalEvent.interval) {
        // If interval is lower, it occurs more often, so it's less important.
        return false;
    }
    // This shouldn't occur if all the checks on create side are correct. Overlaps should only happen when the frequency and/or interval are different.
    return false;
}
