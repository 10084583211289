import React, { ReactElement } from "react";
import Player from "../Player/Player";
import { NoContent } from "../components/NoContent";
import { useFetchScene } from "../../../hooks/api/scene/useFetchScene";
import { ResponseErrorRetryHandler } from "../../../components/ResponseErrorRetryHandler/ResponseErrorRetryHandler";

interface PlayerContainerProps {
    sceneId: string;
}

/**
 * Container component around the player which fetches the channel to show.
 * @param props
 * @constructor
 */
function SceneContainer(props: PlayerContainerProps): ReactElement {
    const { response, isLoading, countdown, error } = useFetchScene(
        props.sceneId,
    );

    return (
        <ResponseErrorRetryHandler
            isLoading={isLoading}
            error={error}
            countdown={countdown}
        >
            {response ? (
                <Player
                    scenes={[{ scene: response, duration: 60, position: 1 }]}
                />
            ) : (
                <NoContent />
            )}
        </ResponseErrorRetryHandler>
    );
}

export default SceneContainer;
